<template>
  <AvatarRoot :class="styles({ size, class: props.class })">
    <slot>
      <slot name="image">
        <AvatarImage
          v-if="src"
          :src="src"
          :alt="alt"
          :class="imageClass"
          @loading-status-change="onLoadingStatusChange"
        />
      </slot>
      <slot name="fallback">
        <AvatarFallback
          :delay-ms="delayMs"
          :class="fallbackClass"
          :fallback="fallback"
        />
      </slot>
    </slot>
  </AvatarRoot>
</template>

<script lang="ts" setup>
import { AvatarRoot } from "radix-vue";

const styles = tv({
  base: "relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full",
  variants: {
    size: {
      sm: "h-8 w-8",
      xs: "h-6 w-6",
    },
  },
});

const props = withDefaults(
  defineProps<{
    class?: any;
    imageClass?: any;
    fallbackClass?: any;
    src?: string;
    alt?: string;
    fallback?: string;
    delayMs?: number;
    onLoadingStatusChange?: (
      v: "idle" | "loading" | "loaded" | "error",
    ) => void;

    size?: keyof typeof styles.variants.size;
  }>(),
  {},
);
</script>
